@mixin reset-button {
  text-decoration: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  text-align: unset;

  &:disabled {
    pointer-events: none;
    cursor: default;
  }
}
