@use '~styles/variables' as vars;

.container {
  list-style-type: none;
  gap: 0.5rem;
  padding: 0;
  margin: 0;
}

.divider {
  width: 1px;
  height: 1.25rem;
  background-color: vars.$grey-mist;
}

.action {
  transition: background-color 250ms ease 0s;
  border-radius: 0.25rem;
  appearance: none;
  background: none;
  border: none;

  @each $tag, $color in vars.$colors {
    &--#{$tag} {
      color: $color;
      &:hover,
      &:focus {
        background-color: rgba($color, 0.08);
      }

      &:active {
        background-color: rgba($color, 0.16);
      }
    }
  }
}
