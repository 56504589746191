@use '~styles/variables' as var;
@use '~styles/utils' as utils;
@import '~styles/styles.scss';

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - $footer-height - $header-height);
  @media #{$bp-mobile-max} {
    min-height: calc(100vh - $footer-mobile-height - $header-mobile-height);
  }
}

.tapestry {
  position: absolute;
  top: 0;

  width: utils.toRem(263px);
  height: utils.toRem(238px);

  @media #{$bp-mobile-max} {
    width: utils.toRem(151px);
    height: utils.toRem(133px);
  }

  &--reduced {
    width: utils.toRem(129px);
    height: utils.toRem(177px);

    @media #{$bp-mobile-max} {
      width: utils.toRem(57px);
      height: utils.toRem(74px);
    }
  }

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }
}

@each $key, $color in var.$colors {
  .bg-#{$key} {
    background-color: $color;
  }
}
