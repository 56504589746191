@use '~styles/utils' as utils;
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/variables' as var;
@import '~styles/styles.scss';

.headerContainer {
  height: var.$header-height;
  display: flex;
  align-items: center;
  padding: 2rem;
  justify-content: space-between;
  color: $color-cf-dark-grey;
  border-bottom: utils.toRem(2px) $color-athens-grey solid;
  background: var.$color-white;
}

.headerNav {
  display: flex;
  height: 100%;
  font-weight: 600;
}

.navLink {
  height: 100%;
  margin-left: 2.5rem;
  color: $color-cf-dark-grey;
  &:not(.active) {
    &:hover {
      color: $color-cf-dark-grey;
      border-bottom: utils.toRem(2px) $color-cf-dark-grey solid;
      text-decoration: none;
    }
  }
  &:hover {
    color: $color-cf-blue;
    border-bottom: utils.toRem(2px) $color-cf-blue solid;
    text-decoration: none;
  }
}

.localeButton {
  margin-left: 1rem;
  &:hover {
    border-bottom: utils.toRem(2px) $color-cf-dark-grey solid;
  }
}

.navDivider {
  margin-left: 1rem;
  border-left: utils.toRem(1px) $color-athens-grey solid;
}

.mobileHeaderContainer {
  height: var.$header-mobile-height;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: utils.toRem(1px) $color-athens-grey solid;
  z-index: map-get(var.$z-index, header);
  background: var.$color-white;
}

.innerMenuContainer {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: utils.toRem(18px);
}

.iconButton {
  margin-right: 1rem;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
}

.mobileNav {
  top: var.$header-mobile-height;
  height: calc(100% - var.$header-mobile-height);
  width: 100%;
  position: absolute;
  z-index: map-get(var.$z-index, header);
  background-color: $color-white;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s linear;
}

.mobileNavBody {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem 1rem;
}

.mobileNavLink {
  margin-bottom: 1.5rem;
  color: $color-cf-dark-grey;
  font-weight: 600;
  &:hover {
    color: $color-cf-blue;
  }
}

.mobileNavDivider {
  width: 100%;
  border-bottom: utils.toRem(1px) $color-athens-grey solid;
}

.mobileLocaleButton {
  margin-top: 1.5rem;
  font-weight: 600;
  background: none;
  color: $color-midnight-blue;
  border: none;
  padding: 0;
  display: inline-flex;
  align-items: flex-start;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.button {
  font-weight: 600;
  background: none;
  border: none;
  padding: 0;
  display: inline-flex;
  align-items: flex-start;
}

.active {
  color: $color-cf-blue;
  @media #{$bp-mobile-min} {
    border-bottom: utils.toRem(2px) $color-cf-blue solid;
  }
}
