@font-face {
  font-family: 'ProximaNova';
  src: url('./fonts/ProximaNova-Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNovaBold';
  src: url('./fonts/ProximaNova-Bold.otf') format('opentype');
  font-display: swap;
}

.font-proxima-nova-regular {
  font-family: 'ProximaNova', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-proxima-nova-bold {
  font-family: 'ProximaNovaBold', sans-serif;
  font-weight: 700;
  font-style: normal;
}
