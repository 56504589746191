@use 'styles/utils/' as utils;
@use 'styles/variables' as var;
@use 'styles/mixins/reset' as reset;

.accordion {
  &Container {
    border-bottom: 1px solid var.$grey-mist;
  }

  &Body {
    padding: utils.toRem(8px) 0;
    display: flex;
    flex-direction: column;

    &Item {
      @include reset.reset-button;
      cursor: pointer;
      display: flex;
      gap: utils.toRem(16px);
      align-items: center;
      padding: utils.toRem(8px) 0;
      &:hover {
        background-color: var.$color-light-grey;
      }
    }
  }

  &Header {
    @include reset.reset-button;
    cursor: pointer;
    padding: utils.toRem(16px) 0;
    border-bottom: 1px solid var.$grey-mist;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &Last {
    border-bottom: none;
  }
}
.titleWrapper {
  padding: utils.toRem(8px) 0;
  border-bottom: 1px solid var.$grey-mist;
}

.modalContentWrapper {
  padding-bottom: utils.toRem(24px);
}

.clearButton {
  background-color: var.$color-cf-dark-grey;
  border-color: var.$color-cf-dark-grey;
  margin-top: 0;
}

.radioButtonContainer {
  width: utils.toRem(24px);
  height: utils.toRem(24px);
  padding-bottom: utils.toRem(24px);

  .radioButton {
    &:after {
      width: utils.toRem(24px);
      height: utils.toRem(24px);
    }
    &:checked:after {
      width: utils.toRem(24px);
      height: utils.toRem(24px);
      border-width: utils.toRem(8px);
    }
  }
}

.checkbox {
  &:checked:not(:active) ~ :global(.custom-control-label)::before {
    background-color: var.$color-bay-blue;
  }

  &:after {
    width: utils.toRem(20px);
    height: utils.toRem(20px);
  }
  &:checked:after {
    background-color: var.$color-bay-blue;
  }
}

.modal {
  &Body {
    overflow: scroll;
    max-height: 70vh;
    min-height: 70vh;
  }

  &Footer {
    flex-direction: row;
    gap: utils.toRem(8px);
    flex-wrap: nowrap;
    margin-top: utils.toRem(16px);
  }

  &Container {
    border-radius: 0;
  }

  &CloseButton {
    right: utils.toRem(4px);
    top: utils.toRem(-12px);
  }
}
