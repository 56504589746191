@use '~styles/variables' as var;
$anim-dur: 0.25s;

.container {
  flex-direction: column;
  width: 24rem;
  height: 18rem;
  display: flex;
}

.items {
  list-style-type: none;
  overflow: auto;
  width: 100%;
  padding: 0;
  margin: 0;
}

.item {
  transition: background-color $anim-dur ease-in-out;
  display: grid;
  text-align: start;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 0.75rem 1rem;
  background: none;
  width: 100%;
  border: none;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.06);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.12);
  }
}

.text {
  padding: 0.75rem 1rem;
}

.footer {
  border-top: 1px solid var.$grey-mist;
  justify-self: flex-end;
  justify-content: flex-end;
  display: flex;
  margin-top: auto;
  padding: 1rem;
  width: 100%;
}

.btn {
  font-size: 0.875rem;
  padding: 0.25rem 0.75rem;
}
