@use '~styles/variables' as var;

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8ad;
  overflow-y: hidden;
  z-index: map-get(var.$z-index, full-page-loader);
}

.loader {
  left: 50%;
  top: 50%;
  position: absolute;
}
