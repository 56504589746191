@use '~styles/utils' as utils;
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/mixins//input.scss' as input;
@use '~styles/variables' as var;
@use '~styles/mixins/reset' as reset;
@import '~styles/styles.scss';

.filterButton {
  @include reset.reset-button;

  cursor: pointer;
  width: 100%;

  display: flex;
  gap: utils.toRem(4px);

  padding: utils.toRem(16px);

  border-top: 1px solid var.$grey-mist;
  border-bottom: 1px solid var.$grey-mist;

  align-items: center;
  justify-content: center;

  &Text {
    font-size: utils.toRem(14px);
    line-height: utils.toRem(24px);
  }
}

.desktopFilter {
  display: flex;
  gap: utils.toRem(8px);

  position: relative;

  &Wrapper {
    z-index: map-get(var.$z-index, dropdown-filter-container);
  }

  &Button {
    @include reset.reset-button;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: utils.toRem(4px);

    &:focus {
      outline: 2px solid var.$color-bahama-blue;
      outline-offset: 1px;
    }

    &Icon {
      color: var.$color-bahama-blue;
      filter: brightness(0) saturate(100%) invert(16%) sepia(99%) saturate(2773%) hue-rotate(186deg)
        brightness(98%) contrast(101%);
    }
  }

  &Modal {
    background-color: var.$color-white;
    border: 1px solid var.$grey-mist;
    min-width: utils.toRem(280px);
    max-width: fit-content;
    max-height: utils.toRem(286px);
    overflow: scroll;
    position: absolute;
    left: utils.toRem(180px);
    box-shadow: 0 utils.toRem(4px) utils.toRem(8px) 0 #0000001f;

    &TitleWrapper {
      border-bottom: 1px solid var.$grey-mist;
      padding: utils.toRem(16px);
    }

    &Body {
      padding: utils.toRem(8px) 0;
      display: flex;
      flex-direction: column;

      &Item {
        @include reset.reset-button;
        cursor: pointer;
        display: flex;
        gap: utils.toRem(16px);
        align-items: center;
        padding: utils.toRem(8px) utils.toRem(16px);
        &:hover {
          background-color: var.$color-light-grey;
        }
      }
    }

    &RadioButton {
      &:after {
        width: utils.toRem(24px);
        height: utils.toRem(24px);
      }
      &:checked:after {
        width: utils.toRem(24px);
        height: utils.toRem(24px);
        border-width: utils.toRem(8px);
      }

      &Container {
        width: utils.toRem(24px);
        height: utils.toRem(24px);
      }
    }

    &Checkbox {
      &:checked:not(:active) ~ :global(.custom-control-label)::before {
        background-color: var.$color-bay-blue;
      }

      &:after {
        width: utils.toRem(20px);
        height: utils.toRem(20px);
      }
      &:checked:after {
        background-color: var.$color-bay-blue;
      }
    }
  }
}
