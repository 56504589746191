@use '../variables' as var;

@mixin base {
  color: var.$color-bahama-blue;
  text-decoration: underline;

  &:focus {
    outline: 2px solid var.$color-bahama-blue;
    outline-offset: 1px;
  }
}
