@use '~styles/utils' as utils;
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/mixins/input.scss' as input;
@use '~styles/variables' as var;
@use '~styles/mixins/reset' as reset;
@import '~styles/styles.scss';

.orderOption {
  &Card {
    border: 1px solid var.$color-light-grey;
    border-radius: utils.toRem(4px);
    padding: utils.toRem(16px);
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);
    width: auto;

    @media #{var.$bp-mobile-min} {
      padding: utils.toRem(24px);
    }
  }

  &Service {
    display: flex;
    align-items: flex-end;
    gap: utils.toRem(8px);

    &Error {
      align-items: center;
    }

    &Select {
      display: flex;
      flex-direction: column;
      gap: utils.toRem(8px);
      width: 100%;
    }

    &Button {
      padding: utils.toRem(12px) utils.toRem(16px);
      width: fit-content;
      border: none;

      @media #{var.$bp-mobile-min} {
        padding-bottom: utils.toRem(20px);
      }

      &Error {
        padding-bottom: utils.toRem(12px);
      }
    }
  }
}

.method {
  display: flex;
  flex-direction: column;
  gap: utils.toRem(16px);

  &Option {
    &Wrapper {
      display: flex;
      flex-direction: column;
      gap: utils.toRem(16px);

      @media #{var.$bp-mobile-min} {
        flex-direction: row;
        gap: utils.toRem(24px);
      }
    }
  }
}

.checkbox {
  &:checked:not(:active) ~ :global(.custom-control-label)::before {
    background-color: var.$color-bay-blue;
  }

  &:after {
    width: utils.toRem(20px);
    height: utils.toRem(20px);
  }
  &:checked:after {
    background-color: var.$color-bay-blue;
  }

  &Wrapper {
    @include reset.reset-button;
    display: flex;
    gap: utils.toRem(16px);
  }
}

.readOnly {
  display: flex;
  flex-direction: column;
  gap: utils.toRem(16px);
  @media #{var.$bp-mobile-min} {
    flex-direction: row;
    gap: utils.toRem(24px);
  }

  &Image {
    height: utils.toRem(16px);
    width: fit-content;
    align-self: flex-start;

    @media #{var.$bp-mobile-min} {
      height: fit-content;
      width: utils.toRem(130px);
      align-self: center;
    }
  }

  &Fields {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);
    overflow: hidden;

    @media #{var.$bp-mobile-min} {
      flex-direction: column;
      gap: utils.toRem(16px);
    }
  }

  &Method {
    display: flex;
    gap: utils.toRem(8px);

    height: fit-content;

    &Item {
      background-color: var.$color-light-grey;
      border-radius: utils.toRem(62px);
      width: fit-content;
      padding: 0 utils.toRem(8px);
      text-wrap: nowrap;
    }
  }

  &Button {
    padding: 0;

    &:hover span {
      border-bottom: 2px solid transparent;
    }

    &Text {
      line-height: utils.toRem(24px);
      font-size: utils.toRem(14px);

      @media #{var.$bp-mobile-min} {
        line-height: utils.toRem(26px);
        font-size: utils.toRem(16px);
      }
    }
  }

  &Link {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;

    @media #{var.$bp-mobile-min} {
      flex-direction: row;
      gap: utils.toRem(16px);
    }

    &Text {
      line-height: utils.toRem(24px);
      white-space: nowrap;
      text-overflow: ellipsis;
      &Ellipsis {
        overflow: hidden;
      }
    }
  }
}
