@use '~styles/mixins/typography' as typo;
@use '~styles/variables' as var;

.container {
  display: grid;
  list-style-type: none;
  grid-template-columns: repeat(var(--rg-cols), 1fr);
  grid-gap: 0 0.5rem;
  margin: 0;
  padding: 0;
}

.item {
  min-width: fit-content;
}

.input:global(.custom-radio) {
  display: inline-block;

  & input {
    cursor: pointer;
    z-index: map-get(var.$z-index, input-radio);
  }

  & label {
    @include typo.Display-6();
    color: var.$color-midnight-blue;
    cursor: pointer;
  }

  & label:global(.custom-control-label)::before {
    border-color: var.$utility-blue;
  }

  & input:disabled:checked ~ label:global(.custom-control-label)::before {
    background-color: rgba(var.$utility-blue, 0.5);
  }

  & input:focus ~ label:global(.custom-control-label)::before {
    background-color: rgba(var.$utility-blue, 0.05);
    border-color: var.$utility-blue;
  }

  & input:checked ~ label:global(.custom-control-label)::before {
    background-color: var.$utility-blue;
  }
}
