@use 'styles/mixins/reset.scss' as reset;
@use 'styles/mixins/typography.scss' as typo;
@use 'styles/utils/' as utils;

.clearAll {
  @include reset.reset-button;
  @include typo.Body-Small;
}

.clearAllText {
  text-decoration: underline;
}

.pillContainer {
  overflow-y: scroll;
  max-height: utils.toRem(162px);
}
