@use '~styles/variables' as var;

.check {
  padding: 0;

  & :global(.custom-control-input) {
    z-index: map-get(var.$z-index, input-checkbox);

    &:active ~ :global(.custom-control-label)::before {
      background-color: rgba(var.$utility-blue, 0.35);
      border-color: rgba(var.$utility-blue, 0.5);
    }

    &:focus ~ :global(.custom-control-label)::before {
      box-shadow: 0 0 0 0.2rem rgba(var.$utility-blue, 0.25);
      border-color: transparent;
    }

    &:focus:not(:active) ~ :global(.custom-control-label)::before {
      box-shadow: 0 0 0 0.2rem rgba(var.$utility-blue, 0.25);
      border-color: rgba(var.$utility-blue, 0.5);
    }

    &:checked:not(:active) ~ :global(.custom-control-label)::before {
      background-color: var.$utility-blue;
    }

    &:indeterminate:not(:active) ~ :global(.custom-control-label)::before {
      background-color: var.$utility-blue;
      border-color: var.$utility-blue;
    }
  }

  & :global(.custom-control-label) {
    align-items: center;
    display: flex;
    background: none;
    line-height: 1;
    cursor: pointer;
    gap: 0.75rem;
    width: 100%;

    &::before,
    &::after {
      width: 1.5rem;
      height: 1.5rem;
    }

    &::before {
      position: static;
      display: inline-block;
    }

    &::after {
      inset: 0 auto 0 0;
    }
  }
}
