@use '~styles/mixins/typography' as typo;
@use '~styles/variables' as var;

.pills {
  list-style-type: none;
  flex-wrap: wrap;
  display: flex;
  padding: 0;
  margin: 0;
}

.pill {
  border: 1px solid var.$color-athens-grey;
  margin: 0.5rem 0.5rem 0 0;
  border-radius: 999px;
  padding: 0.5rem 1rem;
  align-items: center;
  min-width: 1px;
  display: flex;
}

.text {
  text-overflow: ellipsis;
  font-size: 0.75rem;
  overflow: hidden;
  min-width: 1px;
  line-height: 1;
}

.close {
  border-radius: 999px;
  margin-left: 0.625rem;
  background-color: transparent;
  display: flex;
  padding: 0;
}

.clearAll {
  @include typo.Body-Small;
  text-decoration: underline;
  color: var.$color-cf-blue;
  margin-top: 0.5rem;
  padding: 0;
}
