@use '~styles/variables' as var;

.container {
  transition: background-color 250ms ease-in-out 0s, border 250ms ease-in-out 0s;
  border: 0.125rem dotted var.$color-cf-blue;
  align-items: center;
  justify-content: center;
  position: relative;
  max-height: 80vh;
  aspect-ratio: 2;
  display: flex;
  padding: 2rem;

  &.dragging {
    background-color: var.$color-light-grey;
  }

  &.error {
    border-color: var.$horizon-red;
    border-style: solid;
  }

  &.disabled {
    background-color: rgba(var.$color-cf-dark-grey, 0.1);
    border-color: var.$color-cf-dark-grey;
    border-style: solid;
  }
}

.input {
  position: absolute;
  appearance: none;
  cursor: pointer;
  opacity: 0;
  margin: auto;
  inset: 0;
  z-index: map-get(var.$z-index, image-input);

  &:disabled {
    cursor: not-allowed;
  }
}

.body {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
}

.text {
  text-align: center;
  pointer-events: none;
}

.delete {
  transition: background-color 250ms ease-in-out 0s;
  background: none;
  position: absolute;
  inset: 0.25rem 0.25rem auto auto;
  cursor: pointer;
  border: none;
  padding: 0.25rem;
  z-index: map-get(var.$z-index, image-input-delete);
  margin: 0;

  &:hover,
  &:focus {
    background-color: rgba(var.$color-dusty-gray, 0.1);
  }

  &:active {
    background-color: rgba(var.$color-dusty-gray, 0.2);
  }
}

.preview {
  height: auto;
  width: 100%;
  padding: 2rem;
}

.modal :global(.modal-dialog) {
  max-width: 40rem;
}

.gallery {
  display: grid;
  grid-template-columns: 1fr;
  list-style-type: none;
  gap: 1rem;
  padding: 0;
  margin: 0;

  @media #{var.$bp-m} {
    grid-template-columns: 1fr 1fr;
  }
}

.galleryBtn {
  z-index: map-get(var.$z-index, image-input-gallery-button);
}

.galleryItem {
  transition: background-color 250ms ease-in-out 0s, border-color 250ms ease-in-out 0s,
    opacity 250ms ease-in-out 0s;
  padding: 0.5rem;

  &.active {
    background-color: var.$color-live-medium-blue;
    border-color: var.$color-live-medium-blue;
    opacity: 0.7;
  }
}

.galleryImg {
  height: auto;
  width: 100%;
}
