@use '~styles/utils' as utils;
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/mixins/button.scss' as button;
@use '~styles/variables' as var;
@use '~styles/mixins/reset' as reset;
@import '~styles/styles.scss';

.container {
  padding-left: 0;
  padding-right: 0;
  display: block;

  max-width: 200vw;

  &Row {
    @media #{var.$bp-tablet-min} {
      margin: utils.toRem(40px);
    }
  }
}

.main {
  &Left {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(24px);
    background-color: var.$color-white;
    padding: utils.toRem(40px) utils.toRem(24px) utils.toRem(16px) utils.toRem(24px);

    @media #{var.$bp-mobile-max} {
      padding: 0;
      gap: utils.toRem(16px);
    }
  }

  &Right {
    background-color: var.$color-white;
  }
}

.modal {
  &Delete {
    &Button {
      @include button.modal-button-styles;
      background-color: var.$horizon-red;
      border-color: var.$horizon-red;

      &:hover {
        background-color: var.$horizon-red;
        border-color: var.$horizon-red;
      }
    }
  }
  &Submit {
    &Body {
      line-height: utils.toRem(24px);
      @media #{var.$bp-mobile-min} {
        line-height: utils.toRem(28px);
      }

      &Link {
        @include reset.reset-button();
        color: var.$color-cf-blue;
        text-decoration-line: underline;

        @include typo.Body-Main;
        line-height: utils.toRem(24px);

        @media #{var.$bp-mobile-min} {
          @include typo.Body-Large;
          line-height: utils.toRem(28px);
        }
      }
    }

    &Footer {
      justify-content: flex-end;
      gap: utils.toRem(8px);

      @media #{var.$bp-mobile-min} {
        gap: utils.toRem(16px);
        margin-top: utils.toRem(16px);
      }
    }

    &Button {
      @include button.modal-button-styles;
    }
  }
}
