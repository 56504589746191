@use '~styles/mixins/input';
@use '~styles/variables' as vars;

.frame {
  position: relative;
}

.input {
  @include input.input-base();
  appearance: none;
  padding-right: calc(2rem + 12px);

  &.placeholder {
    // Default color for placeholders
    color: #757575;
  }
}

.caret {
  margin: auto 1rem auto auto;
  position: absolute;
  inset: 0;
}
