@use '~styles/utils' as utils;
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/mixins/input.scss' as input;
@use '~styles/mixins/button.scss' as button;
@use '~styles/variables' as var;
@use '~styles/mixins/reset' as reset;
@import '~styles/styles.scss';

.title {
  &Container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    padding: 0 utils.toRem(16px);
    margin-bottom: utils.toRem(-8px);

    @media #{var.$bp-mobile-max} {
      flex-direction: column;
      align-items: flex-start;
    }

    &Text {
      display: flex;
      flex-direction: column;

      @media #{var.$bp-mobile-max} {
        padding-top: utils.toRem(16px);
        padding-bottom: 0;
      }
    }
  }

  &Button {
    width: fit-content;

    font-size: utils.toRem(16px);
    line-height: utils.toRem(24px);
    font-weight: 600;
    padding: utils.toRem(12px) 0;

    &:hover span {
      border-bottom: 2px solid transparent;
    }

    &:disabled {
      background-color: transparent;
    }
  }
}

.search {
  &Container {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(8px);

    @media #{var.$bp-mobile-max} {
      padding: 0 utils.toRem(16px);
    }
  }

  &Description {
    line-height: utils.toRem(24px);

    @media #{var.$bp-mobile-max} {
      line-height: utils.toRem(18px);
    }
  }

  &Input {
    padding: utils.toRem(12px) utils.toRem(9px) utils.toRem(11px) utils.toRem(42px);
    @include typo.Body-Main();
    color: var.$color-black;
    border-bottom-color: var.$color-cf-blue;
    border-bottom-width: 1px;

    &:focus {
      border-bottom-width: 1px;
    }
  }
}

.tabs {
  &Container {
    @media #{var.$bp-mobile-min} {
      display: flex;
      flex-direction: column;
      gap: utils.toRem(16px);
    }
  }
}

.tabItemContainer {
  @media #{var.$bp-mobile-max} {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);
    padding: utils.toRem(16px) utils.toRem(16px) utils.toRem(40px) utils.toRem(16px);
  }
}

.pagination {
  padding: utils.toRem(40px) 0 utils.toRem(64px) 0;
  @media #{var.$bp-mobile-min} {
    padding: utils.toRem(24px) 0;
  }
}
