@use '~styles/mixins/input';
@use '~styles/mixins/button';
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/mixins/link';
@use '~styles/utils' as utils;
@import '~styles/variables';

@mixin input-focus {
  outline: 2px solid $utility-blue;
  outline-offset: 2px;
}

.okta-form-title {
  display: none;
}

.okta-sign-in-header {
  display: none;
}

#okta-sign-in {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  min-width: 0;

  .auth-content {
    margin-top: 2rem;
    padding: 0;
    overflow-y: hidden;
    border: none;
  }

  .focused-input {
    box-shadow: none;
  }

  .o-form label {
    @include typo.Body-Small();
  }

  &.auth-container {
    // Wrappers
    .auth-content {
      max-width: none;
      padding-top: 0;
    }

    &.main-container {
      @include typo.Body-Regular();
      border: none;
      box-shadow: none;
      color: $color-cf-dark-grey;
    }

    // Input label
    .okta-form-label {
      @include typo.Body-Regular();
      color: $color-cf-dark-grey;
      padding: 0;
      margin: 0.5rem 0;
    }

    // Inputs
    .okta-form-input-field input[type] {
      @include input.input-base();
      border-bottom: 1px solid $utility-blue;

      &:focus {
        @include input.input-focus();
      }
    }

    .okta-form-input-field {
      border: none;
    }

    // Button
    .o-form-button-bar {
      padding: 4px 4px;

      &.focused-input {
        margin-bottom: 0;
      }
    }

    .button-primary {
      @include typo.Display-7();
      background: $utility-blue;
      border-radius: utils.toRem(4px);
      height: utils.toRem(38px);

      @media #{$bp-desktop} {
        @include typo.Display-5();
        height: utils.toRem(60px);
      }

      &:focus {
        box-shadow: none !important;
        @include input-focus;
      }
    }

    // Field level errors
    .o-form-input-error .icon-16 {
      display: none;
    }

    .o-form-explain.o-form-input-error {
      padding-left: 0;
      color: $utility-red;
    }

    .o-form-has-errors.input-fix {
      border: 1px solid $utility-red;

      & > input[type] {
        border: none;
      }
    }

    .auth-footer {
      margin-top: 1rem;
      padding: 4px;
    }

    // Back to sign in link MFA
    .auth-footer .goto {
      @include typo.Body-Small();
      @include link.base;
      color: $color-cf-dark-grey;
      float: left;
    }

    // MFA
    .o-form-fieldset-container {
      display: flex;
      flex-direction: column;
    }

    .resend-email-infobox {
      order: -1;
    }
  }

  // Password
  .password-toggle .eyeicon {
    opacity: 1 !important;
    &::before {
      color: $color-cf-dark-grey;
    }
  }

  // Checkbox
  .o-form .custom-checkbox {
    margin-left: 5px;
  }

  .o-form .custom-checkbox label {
    color: $color-cf-dark-grey;
    font-size: utils.toRem(16px);
    padding-left: utils.toRem(40px);
    background-image: none;
    @media #{$bp-desktop} {
      font-size: utils.toRem(13px);
    }
  }

  .o-form .custom-checkbox input {
    top: utils.toRem(4px);
  }

  .o-form .custom-checkbox.focused-input {
    input {
      @include input-focus;
    }
  }

  .o-form input[type='checkbox'] {
    opacity: 100;
  }

  .o-form-explain.o-form-input-error {
    @include typo.Body-Small();
    color: $utility-red;
  }

  // Banner errors
  .o-form .infobox {
    @include typo.Body-Regular();
    background-color: $color-background-red;
    border: 1px solid $utility-red;
    border-radius: utils.toRem(4px);
    color: $color-black;
  }

  .infobox-error:before {
    opacity: 0;
  }

  // Banner icon
  .error-16:before {
    color: $utility-red;
    font-size: utils.toRem(24px);
    line-height: utils.toRem(22px);
  }

  // Banner MFA errors
  .o-form .infobox-warning {
    border: solid 1px $utility-blue;
    background-color: $color-background-blue;
  }

  .infobox-warning:before {
    opacity: 0;
  }

  .warning-16:before {
    color: $utility-blue;
    font-size: utils.toRem(24px);
    line-height: utils.toRem(22px);
  }

  .mfa-verify-email .resend-email-btn {
    @include link.base();
  }
}

//help links
.link.help.js-help {
  display: none;
}
