// colors
$color-white: #ffffff;
$color-black: #000000;
$color-grey: #697082;
$color-light-grey: #f2f4f9;
$color-cf-blue: #0176ae;
$color-cf-red: #dc3838;
$color-athens-grey: #e7e9ed;
$color-live-medium-blue: #389fcc;
$color-dusty-gray: #969696;
$color-bahama-blue: #006296;
$color-cf-dark-grey: #55585a;
$color-concrete: #f3f3f3;
$color-bay-blue: #003a5d;
$color-sky-blue: #b8dae5;
$color-pale-blue: #edf6f8;
$grey-mist: #dadfe1;
$horizon-red: #dd0031;
$pale-blue: #edf6f8;
$color-rose-petal: #efc7cd;
$color-light-green: rgba(142, 209, 127, 0.5);
$color-light-yellow: rgba(255, 207, 36, 0.5);
$color-light-red: rgba(255, 137, 125, 0.5);
$color-light-blue: rgba(184, 218, 229, 0.25);

// utility colors
$utility-blue: #006296;
$utility-red: #dd0031;
$utility-yellow: #f9c642;
$utility-green: #4f7c2b;

// Background colors
$color-background-red: rgba(239, 199, 205, 0.1);
$color-background-blue: rgba(184, 218, 229, 0.1);
$color-background-blue-opacity-20: rgba(184, 218, 229, 0.2);

//Primary Button
$color-midnight-blue: #0d2240;
$color-live-light-blue: #bdffff;

$colors: (
  'white': $color-white,
  'black': $color-black,
  'grey': $color-grey,
  'light-grey': $color-light-grey,
  'cf-blue': $color-cf-blue,
  'cf-red': $color-cf-red,
  'athens-grey': $color-athens-grey,
  'live-medium-blue': $color-live-medium-blue,
  'dusty-gray': $color-dusty-gray,
  'bahama-blue': $color-bahama-blue,
  'cf-dark-grey': $color-cf-dark-grey,
  'concrete': $color-concrete,
  'bay-blue': $color-bay-blue,
  'midnight-blue': $color-midnight-blue,
  'live-light-blue': $color-live-light-blue,
  'sky-blue': $color-sky-blue,
  'grey-mist': $grey-mist,
  'horizon-red': $horizon-red,
  'pale-blue': $pale-blue,
  'rose-petal': $color-rose-petal,
  'light-green': $color-light-green,
  'light-yellow': $color-light-yellow,
  'light-red': $color-light-red,
  'light-blue': $color-light-blue,
);

// Component sizes
$footer-height: 26.5rem;
$footer-mobile-height: 36rem;
$footer-mobile-nav-height: 47rem;
$header-height: 6.75rem;
$header-mobile-height: 3.75rem;

// Breakpoints
$screen-s: 720px;
$screen-m: 960px;
$screen-l: 1280px;
$screen-xl: 1600px;

// Media Utils, example usage: `@media #{var.bp-s} { ... }`
$bp-s: 'screen and (min-width: #{$screen-s})';
$bp-m: 'screen and (min-width: #{$screen-m})';
$bp-l: 'screen and (min-width: #{$screen-l})';
$bp-xl: 'screen and (min-width: #{$screen-xl})';

$bp-s-max: 'screen and (max-width: #{$screen-s - 1})';
$bp-m-max: 'screen and (max-width: #{$screen-m - 1})';
$bp-l-max: 'screen and (max-width: #{$screen-l - 1})';
$bp-xl-max: 'screen and (max-width: #{$screen-xl - 1})';

// breakpoint for mobile => desktop designs
$bp-desktop: $bp-m;

$bp-mobile-max: 'screen and (max-width: #{$screen-s - 1})';
$bp-tablet-max: 'screen and (max-width: #{$screen-m - 1})';
$bp-desktop-max: 'screen and (max-width: #{$screen-l - 1})';

$bp-mobile-min: 'screen and (min-width: #{$screen-s})';
$bp-tablet-min: 'screen and (min-width: #{$screen-m})';
$bp-desktop-min: 'screen and (min-width: #{$screen-l})';

// Fonts
$base-font-size: 16px;
$body-font-family: 'ProximaNova';
$heading-font-family: 'ProximaNovaBold';

$sizes: (
  mobile: $screen-s,
);

// Levels

$z-index: (
  'text-highlight': -1,

  'food-provider-form-header': 0,
  'dropdown-single-select-container': 0,

  'image-input': 1,
  'input-checkbox': 1,
  'input-radio': 1,
  'modal-input': 1,

  'widget-container': 1,

  'image-input-delete': 2,
  'image-input-gallery-button': 2,

  'dropdown-filter-container': 10,

  'dropdown-multi-select-container': 20,
  'dropdown-single-select-menu': 20,

  'dropdown': 50,

  'header': 99,

  'full-page-loader': 1000,

  'dropdown-multi-select-menu': 9999,
);
