@use '~styles/utils' as utils;
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/mixins/input.scss' as input;
@use '~styles/mixins/reset' as reset;
@use '~styles/variables' as var;
@import '~styles/styles.scss';

.dropdownAnchor {
  position: relative;
}

.dropdownContainer {
  position: absolute;
  width: 100%;
  z-index: map-get(var.$z-index, dropdown-multi-select-container);
  background-color: $color-white;
  max-height: utils.toRem(265px);
  overflow-y: hidden;
}

.dropdownItemContainer {
  overflow-y: scroll;
  max-height: utils.toRem(200px);
  margin-bottom: utils.toRem(1px);
  border: utils.toRem(1px) solid $grey-mist;
  border-top: none;
  padding: 0;
}

.dropdownOpen {
  min-height: utils.toRem(200px);
}

.dropdownItem {
  @include typo.Body-Small;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: utils.toRem(40px);
  padding: 0.5rem 1rem 0.5rem;
}

.unitDropdownItem {
  justify-content: flex-start;
  &:hover,
  &:focus {
    background-color: $pale-blue;
    cursor: pointer;
  }
}

.retailerGroupItem {
  &:hover,
  &:focus {
    background-color: $pale-blue;
    cursor: pointer;
  }
}

.caretRight {
  transform: rotate(-90deg);
}

.propertyNameItem {
  background-color: $color-light-grey;
  font-size: utils.toRem(12px);
  color: $color-cf-dark-grey;
}

.backButton {
  width: 100%;
  height: utils.toRem(40px);
  &:hover,
  &:focus {
    background-color: $pale-blue;
    cursor: pointer;
  }
}

.backButtonText {
  @include typo.Body-Small;
  font-weight: 700;
}

.checkboxEmpty {
  margin-right: 0.5rem;
  background-color: $color-white;
  border-radius: 3px;
}

.checkboxFilled {
  margin-right: 0.5rem;
  background-color: $color-bay-blue;
  border-radius: 3px;
}

.textInput {
  @include input.input-base;
  &:focus {
    @include input.input-focus;
  }
  padding-left: 3rem;
  height: utils.toRem(48px);
}

.inputContainer {
  position: relative;
}

.inputIcon {
  top: 0.7rem;
  left: 1rem;
  position: absolute;
  @include reset.reset-button;
}

.doneContainer {
  position: sticky;
  bottom: 0;
  height: utils.toRem(65px);
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 1rem;
  border: utils.toRem(1px) solid $grey-mist;
  border-top: 0;
}

.doneButton {
  height: utils.toRem(38px);
  width: utils.toRem(66px);
  font-size: utils.toRem(14px);
}

.inputIconWhite {
  @include reset.reset-button;
  top: 0;
  left: 0;
  position: absolute;
  background-color: $color-white;
  height: utils.toRem(46px);
  width: utils.toRem(36px);
}

.errorText {
  @include input.input-error-text();
}

.error {
  @include input.input-error();

  &:focus {
    @include input.input-error();
  }
}
