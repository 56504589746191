@use '~styles/mixins/typography';
@use '~styles/variables' as var;

$border-thickness: 2px;
$placeholder-text-color: #55585a;
$input-text-color: #0d2240;

@mixin input-base {
  @include typography.Display-6();
  transition: border-color 250ms ease-in-out 0s;
  box-shadow: none;
  width: 100%;
  min-width: 90%;
  border: $border-thickness solid var.$color-concrete;
  background: var.$color-concrete;
  border-bottom: $border-thickness solid var.$color-bahama-blue;
  border-radius: 2px 2px 0px 0px;
  box-sizing: border-box;
  padding: 0.875rem 1rem;

  @media #{var.$bp-s-max} {
    line-height: 2.5rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  &:disabled,
  &:global(.disabled) {
    border-bottom: $border-thickness solid var.$color-cf-dark-grey;
  }

  color: $input-text-color;
  &::placeholder {
    color: $placeholder-text-color;
  }
  &::-ms-input-placeholder {
    color: $placeholder-text-color;
  }
}

@mixin input-focus {
  border-bottom: $border-thickness solid var.$grey-mist;
  outline: none;
}

@mixin input-error {
  border: $border-thickness solid var.$horizon-red;
}

@mixin input-error-text {
  @include typography.Body-Small();
  color: var.$horizon-red;
  margin-top: 0.5rem;
}
