@use './variables' as var;
@import 'styles.scss';
@import './custom-bootstrap.scss';

html,
body,
#root,
.App {
  background-color: white;
  font-size: 16px;
  position: relative;
  min-height: 100vh;
  @extend .font-proxima-nova-regular;
  overflow-x: hidden;
  height: 100%;
}

body {
  position: relative;
  min-height: 100% !important;
  font-family: var.$body-font-family;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var.$body-font-family;
  font-size: var.$base-font-size;
}

*,
:after,
:before {
  box-sizing: border-box;
}

a:hover {
  text-decoration: none;
}

p {
  margin: 0;
}

.error-field {
  .form-control {
    border: 1px solid $color-cf-red !important;
  }
  .custom-select {
    border: 1px solid $color-cf-red !important;
  }
  .custom-image-picker {
    border: 2px dotted $color-cf-red !important;
    margin-bottom: 0.5rem;
  }
  .error-text {
    font-size: 14px;
    color: $color-cf-red;
  }
}

label {
  display: block;
  width: 100%;
}

:root {
  --toastify-color-info: #006296;
  --toastify-color-success: #4f7c2b;
  --toastify-color-warning: #f9c642;
  --toastify-color-error: #dd0031;
  --toastify-font-family: 'ProximaNova';
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
}
