@use '~styles/variables.scss' as var;
@use '~styles/utils' as utils;
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/mixins/reset.scss' as reset;
@import '~styles/styles.scss';

.unique-button {
  justify-content: center;
  border-top: 0;
}

.dialogContainer {
  @media #{$bp-mobile-min} {
    max-width: utils.toRem(560px);
  }
}

.modalContainer {
  padding: utils.toRem(40px) utils.toRem(42px) utils.toRem(31px) utils.toRem(40px);

  @media #{$bp-mobile-max} {
    padding: 1rem;
    padding-top: 28px;
  }
}

.modalHeaderContainer {
  padding: 0;
  border-bottom: none;
  width: 100%;
}

.modalHeader {
  @include typo.Display-3;
  width: calc(100% - utils.toRem(20px));
}

.modalBody {
  @include typo.Body-Large;
  border: none;
  padding: 0;
  margin-top: 0.5rem;
}

.modalFooter {
  border-top: 0;
  justify-content: flex-start;
  padding: 0;
  margin-top: 1.5rem;

  &--fullWidth {
    display: flex;
    flex-direction: column;
  }

  @media #{$bp-mobile-max} {
    display: flex;
    flex-direction: column;
  }
}

.modalFooter:empty {
  display: none;
}

.confirmButton {
  @include typo.Body-Large;
  font-weight: 600;
  margin: 0;
  padding: utils.toRem(14px) 2rem;

  &--fullWidth {
    width: 100%;
  }

  @media #{$bp-mobile-max} {
    width: 100%;
    padding: utils.toRem(7px) 2rem;
    font-size: 14px;
  }
}

.cancelButton {
  @include typo.Body-Large;
  font-weight: 600;
  margin: 0;
  margin-left: 0.5rem;
  padding: utils.toRem(14px) 2rem;

  &--fullWidth {
    width: 100%;
    margin-left: 0;
    margin-top: 0.5rem;
  }

  @media #{$bp-mobile-max} {
    width: 100%;
    padding: utils.toRem(7px) 2rem;
    margin-left: 0;
    font-size: utils.toRem(14px);
    margin-top: 0.5rem;
  }
}

.closeAnchor {
  position: relative;
  height: 0;
}

.closeButton {
  @include reset.reset-button;
  position: absolute;
  top: utils.toRem(-15px);
  @media #{$bp-mobile-max} {
    right: 0;
  }
}

.clearButton {
  background-color: var.$color-cf-dark-grey;
  border-color: var.$color-cf-dark-grey;
}
