@use 'styles/mixins/reset.scss' as reset;
@use 'styles/mixins/typography.scss' as typo;
@use 'styles/variables' as var;
@use 'styles/utils/' as utils;

.gallery {
  display: grid;
  grid-template-columns: 1fr;
  list-style-type: none;
  gap: 1rem;
  padding: utils.toRem(16px) 0;
  margin: 0;

  @media #{var.$bp-m} {
    grid-template-columns: 1fr 1fr;
  }

  @media #{var.$bp-mobile-min} {
    padding: utils.toRem(24px) 0;
  }
}

.galleryBtn {
  z-index: map-get(var.$z-index, image-input-gallery-button);
}

.galleryItem {
  transition: background-color 250ms ease-in-out 0s, border-color 250ms ease-in-out 0s,
    opacity 250ms ease-in-out 0s;
  padding: 0.5rem;

  &.active {
    background-color: var.$color-live-medium-blue;
    border-color: var.$color-live-medium-blue;
    opacity: 0.7;
  }
}

.galleryImg {
  height: auto;
  width: 100%;
}
