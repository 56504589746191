@use 'sass:math';
@use './variables' as var;

@function toRem($size) {
  $remSize: math.div($size, var.$base-font-size);
  @return #{$remSize}rem;
}

// reference: https://accessibility.18f.gov/hidden-content/
.sr-only {
  position: absolute;
  height: 1px;
  width: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  overflow: hidden;
}

.sr-only-focus-visible {
  &:focus {
    position: static;
    width: auto;
    height: auto;
    clip: none;
    margin: 0;
    background: var.$color-white;
  }
}
