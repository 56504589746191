@use 'styles/mixins/reset.scss' as reset;
@use 'styles/mixins/button.scss' as button;
@use 'styles/mixins/typography.scss' as typo;
@use 'styles/variables' as var;
@use 'styles/utils/' as utils;

.modal {
  &ImageContainer {
    position: relative;
  }

  &SelectedImageContainer {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 100%;
    height: utils.toRem(134px);
    overflow: hidden;

    @media #{var.$bp-mobile-min} {
      height: utils.toRem(268px);
    }
  }

  &Option {
    @include reset.reset-button;

    display: flex;
    gap: utils.toRem(8px);

    &Container {
      display: flex;
      gap: utils.toRem(8px);

      flex-direction: column;

      @media #{var.$bp-mobile-min} {
        margin-top: utils.toRem(8px);
        flex-direction: row;
        gap: utils.toRem(24px);
      }
    }

    &Text {
      font-size: utils.toRem(14px);
      line-height: utils.toRem(24px);
      transform: translateY(-1px);
    }

    &RadioButton {
      &:after {
        width: utils.toRem(24px);
        height: utils.toRem(24px);
      }
      &:checked:after {
        width: utils.toRem(24px);
        height: utils.toRem(24px);
        border-width: utils.toRem(8px);
      }

      &Container {
        width: utils.toRem(24px);
        height: utils.toRem(24px);
      }
    }
  }

  &Header {
    @include typo.Display-5;
    line-height: utils.toRem(28px);
  }

  &Footer {
    margin-top: 0;
    @media #{var.$bp-mobile-min} {
      justify-content: flex-end;
      gap: utils.toRem(16px);
      flex-wrap: nowrap;
      margin-top: utils.toRem(16px);
    }
  }

  &Button {
    @include button.modal-button-styles;
  }

  &Drag {
    position: relative;
    border: 1px dashed var.$color-cf-blue;
    display: flex;
    z-index: 1;

    align-items: center;
    justify-content: center;

    height: utils.toRem(136px);
    margin: utils.toRem(16px) 0;

    @media #{var.$bp-mobile-min} {
      height: utils.toRem(270px);
      margin: utils.toRem(24px) 0;
    }

    transition: background-color 0.3s, backdrop-filter 0.3s;

    &.imageSelected {
      &:hover {
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(3px);
      }
    }

    &Content {
      display: flex;
      flex-direction: column;
      gap: utils.toRem(24px);

      align-items: center;
      justify-content: center;

      transition: 0.3s;

      .imageSelected & {
        opacity: 0;
      }

      .imageSelected:hover & {
        opacity: 1;
      }

      &Upload {
        @include reset.reset-button;
      }
    }
  }
}

.input {
  position: absolute;
  appearance: none;
  cursor: pointer;
  opacity: 0;
  inset: 0;
  z-index: map-get(var.$z-index, modal-input);

  height: utils.toRem(136px);

  @media #{var.$bp-mobile-min} {
    height: utils.toRem(270px);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.preview {
  height: auto;
  width: calc(100% - 1px);
  object-fit: cover;
}

.delete {
  position: absolute;
  top: 0.5rem;
  right: -0.5rem;
  transform: translateX(-50%);
  background-color: rgba(var.$color-white, 0.8);
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  z-index: map-get(var.$z-index, image-input-delete);
  margin: 0;

  @media #{var.$bp-mobile-min} {
    top: initial;
    right: initial;
    bottom: 0.5rem;
    left: 50%;
  }

  &:hover,
  &:focus {
    background-color: rgba(var.$color-white, 0.9);
  }

  &:active {
    background-color: rgba(var.$color-white, 1);
  }
}
