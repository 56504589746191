@use '~styles/utils' as utils;
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/mixins/input.scss' as input;
@use '~styles/mixins/button.scss' as button;
@use '~styles/variables' as var;
@use '~styles/mixins/reset' as reset;
@import '~styles/styles.scss';

.form {
  padding: utils.toRem(16px);
  display: flex;
  flex-direction: column;
  gap: utils.toRem(24px);

  @media #{var.$bp-mobile-min} {
    padding: 0 utils.toRem(40px);
    gap: utils.toRem(48px);
  }

  &Readonly {
    padding-bottom: utils.toRem(40px);
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: utils.toRem(16px);

  @media #{var.$bp-mobile-min} {
    gap: utils.toRem(24px);
  }

  &Heading {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(8px);

    @media #{var.$bp-mobile-min} {
      gap: utils.toRem(16px);
    }

    &Subtitle {
      line-height: utils.toRem(24px);
    }
  }

  &Fields {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);

    &Image {
      @media #{var.$bp-mobile-min} {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: utils.toRem(40px);
  padding: utils.toRem(40px);
  padding-top: utils.toRem(64px);

  @media #{var.$bp-mobile-max} {
    gap: utils.toRem(24px);
    padding: utils.toRem(16px);
    padding-bottom: utils.toRem(40px);
  }

  &Line {
    height: 1px;
    background-color: var.$grey-mist;
  }

  &Buttons {
    display: flex;
    gap: utils.toRem(24px);

    @media #{var.$bp-mobile-max} {
      gap: utils.toRem(8px);
    }
  }
}

.textInput {
  padding: utils.toRem(11px) utils.toRem(12px);
  @include typo.Body-Main();
  color: var.$color-cf-blue;
  border-bottom-color: var.$color-cf-blue;
  border-width: 1px;
  width: 100%;

  &:focus {
    border-width: 1px;
  }

  &Error {
    @include input.input-error();
    border-width: 1px;
  }
}

.readOnly {
  &Button {
    padding: 0;

    &:hover span {
      border-bottom: 2px solid transparent;
    }

    &:disabled {
      background-color: transparent;
    }

    &Text {
      line-height: utils.toRem(24px);
      font-size: utils.toRem(14px);

      @media #{var.$bp-mobile-min} {
        line-height: utils.toRem(26px);
        font-size: utils.toRem(16px);
      }
    }
  }
}

.back {
  width: 100%;
  padding-top: utils.toRem(16px);

  &Text {
    @include typo.Display-7();

    width: 100%;
    text-align: left;
  }
}
