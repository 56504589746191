@use '~styles/variables.scss' as var;
@use '~styles/utils';
@use '~styles/mixins/typography.scss';
@use '~styles/mixins/button.scss';

.root {
  @include button.base;
}

.size {
  &-small {
    @include button.size-small;
  }

  &-large {
    @include button.size-large;
  }

  &-stretch {
    width: 100%;
  }
}

.theme {
  &-primary {
    @include button.primary-theme;
  }

  &-secondary {
    @include button.secondary-theme;
  }
}
