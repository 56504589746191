@use '~styles/mixins/typography' as typo;
@use '~styles/variables' as var;

$aligns: left, center, right;

.root {
  white-space: pre-wrap;
}

.type-h1 {
  @include typo.Display-1();
}

.type-h2 {
  @include typo.Display-2();
}

.type-h3 {
  @include typo.Display-3();
}

.type-h4 {
  @include typo.Display-4();
}

.type-h5 {
  @include typo.Display-5();
}

.type-h6 {
  @include typo.Display-6();
}

.type-h7 {
  @include typo.Display-7();
}

.type-body {
  @include typo.Body-Regular();
}

.type-bodySm {
  @include typo.Body-Small();
}

.type-bodyLg {
  @include typo.Body-Large();
}

.type-allCaps {
  @include typo.All-Caps();
}

@each $align in $aligns {
  .align-#{$align} {
    text-align: $align;
  }
}

@each $key, $color in var.$colors {
  .color-#{$key} {
    color: $color;
  }
}

@each $key, $highlightColor in var.$colors {
  .highlight-#{$key} {
    // Highlight styles
    &:before {
      content: '';
      position: absolute;
      z-index: map-get(var.$z-index, text-highlight);
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.3em;
      transform: translateY(-100%);

      background-color: $highlightColor;
    }
  }
}
