@use '~styles/mixins/input';

.input {
  @include input.input-base();
  display: flex;

  &:focus {
    @include input.input-focus();
  }

  &:global(.is-invalid) {
    @include input.input-error();
  }
}
