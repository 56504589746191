@use '../utils';
@import '../variables';
@import '../styles.scss';

// Headings
@mixin Display-1 {
  font-family: $heading-font-family;
  font-size: utils.toRem(32px);
  font-weight: 700;
  line-height: 1.25;

  @media #{$bp-desktop} {
    font-size: utils.toRem(48px);
    line-height: 1.25;
  }
}

@mixin Display-2 {
  font-family: $heading-font-family;
  font-size: utils.toRem(28px);
  line-height: 1.125;
  font-weight: 700;

  @media #{$bp-desktop} {
    font-size: utils.toRem(40px);
    line-height: 1.3;
  }
}

@mixin Display-3 {
  font-family: $heading-font-family;
  font-size: utils.toRem(24px);
  font-weight: 700;
  line-height: 1.125;

  @media #{$bp-desktop} {
    font-size: utils.toRem(32px);
    line-height: 1.325;
  }
}

@mixin Display-4 {
  font-family: $heading-font-family;
  font-size: utils.toRem(18px);
  font-weight: 700;
  line-height: 1.15;

  @media #{$bp-desktop} {
    font-size: utils.toRem(24px);
    line-height: 1.25;
  }
}

@mixin Display-5 {
  font-family: $heading-font-family;
  font-size: utils.toRem(16px);
  font-weight: 600;
  line-height: 1.5;

  @media #{$bp-desktop} {
    font-size: utils.toRem(18px);
    line-height: 1.75;
  }
}

@mixin Display-6 {
  font-family: $heading-font-family;
  font-size: utils.toRem(14px);
  font-weight: 700;
  line-height: 1.5;

  @media #{$bp-desktop} {
    font-size: utils.toRem(16px);
    line-height: 1.625;
  }
}

@mixin Display-7 {
  font-family: $heading-font-family;
  font-size: utils.toRem(12px);
  font-weight: 700;
  line-height: utils.toRem(22px);

  @media #{$bp-desktop} {
    font-size: utils.toRem(14px);
    line-height: utils.toRem(24px);
  }
}

// Body Copy
@mixin Body-Large {
  font-family: $body-font-family;
  font-size: utils.toRem(16px);
  line-height: 1.625;

  @media #{$bp-desktop} {
    font-size: utils.toRem(18px);
    line-height: 1.75;
  }
}

@mixin Body-Regular {
  font-family: $body-font-family;
  font-size: utils.toRem(14px);
  line-height: 1.5;

  @media #{$bp-desktop} {
    font-size: utils.toRem(16px);
    line-height: 1.625;
  }
}

@mixin Body-Small {
  font-family: $body-font-family;
  font-size: utils.toRem(12px);
  line-height: 1.125;

  @media #{$bp-desktop} {
    font-size: utils.toRem(14px);
    line-height: 1.5;
  }
}

@mixin Body-Main {
  font-family: $body-font-family;
  font-size: utils.toRem(14px);
  font-weight: 400;
  line-height: 1.75;

  @media #{$bp-desktop} {
    font-size: utils.toRem(16px);
    line-height: 1.625;
  }
}

@mixin All-Caps {
  @extend .font-proxima-nova-bold;
  font-size: utils.toRem(12px);
  line-height: 1.125;
  text-transform: uppercase;
  letter-spacing: 2px;
}
