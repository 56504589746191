.dropdown-item {
  display: flex;
  &__text {
    margin-left: 0.5rem;
  }
}

.resetBtn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}
