@use '~styles/utils' as utils;
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/mixins/button.scss' as button;
@use '~styles/variables' as var;
@use '~styles/mixins/reset' as reset;
@import '~styles/styles.scss';

.header {
  padding: utils.toRem(40px);

  display: flex;
  gap: utils.toRem(24px);
  justify-content: space-between;
  align-items: center;

  @media #{var.$bp-mobile-max} {
    flex-direction: column;
    padding: utils.toRem(16px);
    gap: utils.toRem(16px);

    align-items: flex-start;
  }

  &Wrapper {
    display: flex;
    height: fit-content;
    gap: utils.toRem(8px);
  }

  &Button {
    @include button.modal-button-styles;

    &Draft,
    &Delete {
      padding: utils.toRem(12px) utils.toRem(16px);
      width: fit-content;
      border: none;
    }
  }
}

.unitTitle {
  z-index: map-get(var.$z-index, food-provider-form-header);
  position: relative;
  width: fit-content;
}

.modal {
  &Delete {
    &Button {
      @include button.modal-button-styles;
      background-color: var.$horizon-red;
      border-color: var.$horizon-red;

      &:hover {
        background-color: var.$horizon-red;
        border-color: var.$horizon-red;
      }
    }
  }
  &Submit {
    &Body {
      line-height: utils.toRem(24px);
      @media #{var.$bp-mobile-min} {
        line-height: utils.toRem(28px);
      }

      &Link {
        @include reset.reset-button();
        color: var.$color-cf-blue;
        text-decoration-line: underline;

        @include typo.Body-Main;
        line-height: utils.toRem(24px);

        @media #{var.$bp-mobile-min} {
          @include typo.Body-Large;
          line-height: utils.toRem(28px);
        }
      }
    }

    &Footer {
      justify-content: flex-end;
      gap: utils.toRem(8px);

      @media #{var.$bp-mobile-min} {
        gap: utils.toRem(16px);
        margin-top: utils.toRem(16px);
      }
    }

    &Button {
      @include button.modal-button-styles;
    }
  }
}
