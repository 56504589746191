@use './variables' as var;

@import './styles.scss';

$grid-columns: 12;
$grid-gutter-width: 24px;

// Overwrite breakpoints used by the grid system (https://getbootstrap.com/docs/4.1/layout/grid/#customizing-the-grid)
$grid-breakpoints: (
  xs: 0,
  sm: var.$screen-s,
  md: var.$screen-m,
  lg: var.$screen-l,
  xl: var.$screen-xl,
);

$container-max-widths: (
  sm: var.$screen-s,
  md: var.$screen-m,
  lg: var.$screen-l,
  xl: var.$screen-xl,
);

@import '~bootstrap/scss/bootstrap';

//Custom Classes
.bg-cf-header {
  background-color: $color-white;
  border-bottom: 1px solid $color-concrete;
}

.bg-cf-action-nav {
  background-color: $color-white;
  border-top: 1px solid $color-concrete;
}

.btn-cf-primary {
  color: $color-white;
  background-color: $color-bahama-blue;
  img {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(234deg) brightness(107%)
      contrast(104%);
  }

  &:hover {
    color: $color-bahama-blue;
    background-color: $color-white;
    border: 1px solid $color-bahama-blue;
    img {
      filter: invert(20%) sepia(65%) saturate(3093%) hue-rotate(183deg) brightness(98%)
        contrast(102%);
    }
  }
}

.btn-cf-secondary {
  color: $color-bahama-blue;
  background-color: $color-white;
  border: 1px solid $color-bahama-blue;

  &:hover {
    color: $color-white;
    background-color: $color-bahama-blue;
  }
}

.btn-cf-negative {
  color: $color-cf-red;
  background-color: $color-white;
  border-color: $color-athens-grey;

  &:hover {
    color: $color-cf-red;
    text-decoration: underline;
  }
}

.btn-cf-text {
  background-color: $color-white;
  color: $color-cf-blue;
  span {
    margin-top: 2px;
    border-bottom: 2px solid transparent;
  }

  &:hover {
    color: $color-cf-blue;
    span {
      border-bottom: 2px solid $color-cf-blue;
    }
  }
}

//Modify Boostrap Classes
.btn {
  border-radius: 4px;
  line-height: 2rem;

  &:disabled {
    background-color: $grey-mist;
    color: $color-dusty-gray;
    img {
      filter: invert(60%) sepia(9%) saturate(0%) hue-rotate(181deg) brightness(98%) contrast(94%);
    }
  }
}

.dropdown-item {
  width: 280px;
}

//Table
.table-striped tbody tr:nth-of-type(odd) {
  background-color: $color-light-grey;
}
.table td,
.table th {
  border-top: none;
}

a {
  color: $color-cf-blue;
}

.input-group-prepend {
  height: 38px;
}

// Pagination

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  background: url('./icons/calendar.svg') center/80% no-repeat;
}
.custom-select {
  border: 1px solid $color-dusty-gray;
  background: url('./icons/caret.svg') right 0.75rem center/18px 9px no-repeat;
  border-radius: 0;
  color: $color-cf-dark-grey;
}

.custom-select:focus {
  @extend .custom-select;
  border-bottom: 4px solid $color-cf-blue;
  box-shadow: none;
}

.form-control {
  border: 1px solid $color-dusty-gray;
  color: $color-cf-dark-grey;
  font-weight: 600;
  border-radius: 0;
  @extend .font-proxima-nova-bold;
}

.form-control:focus {
  @extend .form-control;
  border-bottom: 4px solid $color-cf-blue;
  box-shadow: none;
}

.form-label {
  color: $color-grey;
}

.input-group-text {
  border: 0;
  background-color: $color-bahama-blue;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid $color-cf-blue;
}

$checkbox-size: 1.5rem;

input[type='checkbox'] {
  box-sizing: border-box;
  width: $checkbox-size;
  height: $checkbox-size;
  cursor: pointer;
}

input[type='checkbox']:after {
  width: $checkbox-size;
  height: $checkbox-size;
  background-color: $color-light-grey;
  content: '';
  display: inline-block;
  border: 1px solid $color-cf-blue;
}

input[type='checkbox']:checked:after {
  width: $checkbox-size;
  height: $checkbox-size;
  background: url('./icons/checkmark-white.svg') center/100% no-repeat;
  background-color: $color-cf-blue;
  content: '';
}

input[type='checkbox'] ~ .form-check-label {
  margin-left: 15px;
  margin-top: 6px;
}

//nav bar
.nav-tabs {
  border-bottom: 0;
  .nav-link {
    padding: 1rem 0;
    margin: 0 1rem;
    color: $color-cf-dark-grey;
    &.active {
      border-color: transparent;
      border-bottom: 2px solid $color-bahama-blue;
      color: $color-bahama-blue;
    }

    &:hover {
      border-color: transparent;
      border-bottom: 2px solid $color-cf-dark-grey;
      &.active {
        border-bottom: 2px solid $color-bahama-blue;
        cursor: initial;
      }
    }
  }
}

// Tooltip
.bs-tooltip-right {
  .arrow::before {
    border-right-color: $color-bay-blue;
  }
  .tooltip-inner {
    background-color: $color-bay-blue;
    @extend .font-proxima-nova-regular;
    width: 85%;
    @media #{$bp-l} {
      width: 100%;
    }
  }
}
