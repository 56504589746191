@use '~styles/utils' as utils;
@use '~styles/variables' as var;

.property {
  display: flex;
  flex-direction: column;
  gap: utils.toRem(8px);

  @media #{var.$bp-mobile-min} {
    gap: utils.toRem(24px);
  }
}

.readOnlyLinkText {
  line-height: utils.toRem(24px);
  white-space: nowrap;
  text-overflow: ellipsis;
}
