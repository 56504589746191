@use '~styles/utils' as utils;
@use '~styles/variables' as var;
@import '~styles/styles.scss';

.forgotPasswordPage {
  display: flex;
  justify-content: center;
  align-items: center;
  @media #{var.$bp-m} {
    min-height: utils.toRem(700px);
  }
}

.widgetWrapper {
  position: relative;
  width: 100%;
  padding: utils.toRem(40px);
  background-color: var.$color-white;
  z-index: map-get(var.$z-index, widget-container);
  height: fit-content;

  @media #{var.$bp-s} {
    max-width: 35rem;
    margin: utils.toRem(40px) utils.toRem(40px) utils.toRem(82px);
  }
}

.thankYou {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: utils.toRem(508px);

  @media #{var.$bp-s} {
    min-height: utils.toRem(226px);
  }
}

.text {
  margin-bottom: 2rem;
}
