@use '~styles/variables' as vars;

.container {
  border-left: solid 0.75rem vars.$color-sky-blue;
  background-color: vars.$color-pale-blue;
}

.spinner {
  border-width: 0.25rem;
}

.requests {
  border-top: 1px solid vars.$grey-mist;
  list-style-type: none;
}

.request {
  border-bottom: 1px solid vars.$grey-mist;
  gap: 1rem;
}

.requestInfo {
  min-width: 1px;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.action {
  width: 100%;
  border: none;
  background: none;

  & > img {
    transform: rotate(-90deg);
  }
}
