@use 'styles/variables';
@use 'styles/utils' as utils;
@import 'styles/styles.scss';

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: utils.toRem(308px);

  @media #{$bp-s} {
    min-height: utils.toRem(140px);
  }
}
