@use '~styles/mixins/typography' as typo;
@use '~styles/variables' as var;
@use '~styles/mixins/reset' as reset;
@use '~styles/utils' as utils;
@use '~styles/mixins/input' as input;

.root {
  position: relative;
  &Default {
    z-index: map-get(var.$z-index, dropdown-single-select-container);
  }
}

.rmDefault {
  @include input.input-base;
  @include typo.Display-5;
  appearance: none;
  width: 100%;
  cursor: pointer;

  &:focus {
    @include input.input-focus();
  }

  &.error {
    @include input.input-error();
  }
}

.indicator {
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);

  &Error {
    top: 35%;
  }
}

.errorText {
  @include input.input-error-text();

  &Custom {
    position: relative;
  }
}

.customDropdown {
  @include reset.reset-button;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: utils.toRem(11px) utils.toRem(12px) utils.toRem(11px) utils.toRem(12px);

  border-bottom: 1px solid var.$color-cf-blue;
  background-color: var.$color-concrete;

  cursor: pointer;

  user-select: none;
  box-sizing: border-box;

  &.open {
    border-bottom-width: utils.toRem(4px);
    padding-bottom: utils.toRem(8px);

    &Error {
      padding-bottom: utils.toRem(11px);
    }
  }

  &.disabled {
    cursor: not-allowed;
    border-bottom-color: transparent;
  }

  &Text {
    &Disabled {
      color: var.$color-cf-dark-grey;
    }
  }

  .selectedValue {
    flex: 1;
  }

  .indicator {
    margin-left: 10px;
  }

  &.error {
    @include input.input-error();
    border-width: 1px;
  }
}

.optionsList {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid var.$grey-mist;
  background-color: var.$color-white;
  max-height: utils.toRem(200px);
  overflow-y: auto;
  z-index: map-get(var.$z-index, dropdown-multi-select-menu);
  padding-left: 0;

  .option {
    list-style-type: none;
    padding: utils.toRem(8px) utils.toRem(16px);
    cursor: pointer;

    &:hover,
    &Selected {
      background-color: var.$color-light-blue;
    }

    &[data-focused='true'] {
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
      @-moz-document url-prefix() {
        outline: 5px auto Highlight;
      }
    }

    &Selected {
      cursor: auto;
    }

    &Text {
      @include typo.Body-Small();

      &Selected {
        font-weight: 600;
      }
    }
  }

  &Error {
    top: 66%;
  }
}
.icon {
  &Open {
    margin-right: utils.toRem(-4px);
  }

  &Check {
    margin-right: utils.toRem(8px);
  }
}
