@use '~styles/variables' as var;
@use '~styles/mixins/reset.scss' as reset;
@use '~styles/utils/' as utils;

.widgetContainer {
  margin: utils.toRem(40px) utils.toRem(40px) utils.toRem(82px);
  @media #{var.$bp-mobile-max} {
    margin: 0;
  }
}

.formContainer {
  position: relative;
  background: var.$color-white;
  padding: utils.toRem(40px);
  @media #{var.$bp-mobile-max} {
    padding: 1rem;
    margin: 0;
  }
}

.confirmContainer {
  background: var.$color-white;
  padding: utils.toRem(40px);
  margin: utils.toRem(160px) utils.toRem(40px);
  @media #{var.$bp-mobile-max} {
    padding: 1rem;
    margin: 0;
  }
}

.helpLinks {
  border-top: solid 1px var.$grey-mist;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var.$color-white;
  padding: 1rem;

  @media #{var.$bp-s} {
    padding: 1rem 0 1rem 0;
    display: flex;
    background: none;
    border: none;
  }
}

.terms {
  line-height: utils.toRem(18px);
  margin: 0.5rem 0;
  @media #{var.$bp-s} {
    line-height: utils.toRem(24px);
    margin: 0.25rem 0;
  }
}

.termsLink {
  @include reset.reset-button();
  color: var.$color-bahama-blue;
  text-decoration-line: underline;
}
