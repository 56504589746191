@use '~styles/variables' as vars;

$_size: 2.75rem;

.container {
  gap: 0.75rem;

  & :global(.page-item) {
    & :global(.page-link) {
      transition: background-color 150ms ease 0s, color 150ms ease 0s;
      color: vars.$color-cf-blue;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      line-height: 1;
      border: none;
      height: $_size;
      width: $_size;
      padding: 0;
    }

    &:hover:not(:global(.disabled)):not(:global(.active)) :global(.page-link) {
      background-color: rgba(vars.$color-cf-blue, 0.15);
    }

    &:global(.active) :global(.page-link) {
      background-color: vars.$color-cf-blue;
      color: vars.$color-white;
    }

    &.arrow :global(.page-link) {
      font-size: 2rem;

      & > span {
        margin-top: -0.125rem;
      }
    }

    &.arrow:global(.disabled) {
      visibility: hidden;
    }
  }
}
