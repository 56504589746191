@use 'styles/variables';
@use 'styles/utils' as utils;
@import 'styles/styles.scss';

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: utils.toRem(308px);

  @media #{$bp-s} {
    min-height: utils.toRem(140px);
  }
}

.forgotPasswordButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media #{$bp-s} {
    flex-direction: row;
    margin-top: 2rem;
  }

  :first-child {
    margin: 0 0 1rem;
    @media #{$bp-s} {
      margin: 0 1rem 0 0;
    }
  }

  > button {
    flex: 1;
  }
}
