@use '~styles/variables' as var;
@use '~styles/utils';

.page {
  padding-bottom: 10rem;
  margin-top: 1.5rem;
}

.formContainer {
  background: var.$color-white;
  padding: utils.toRem(40px);
  margin: utils.toRem(40px);
  @media #{var.$bp-mobile-max} {
    padding: 1rem;
    margin: 0;
  }
}
