@use '~styles/variables' as vars;
@use '../UserManagementPage.module' as ctx;

.container {
  @media #{vars.$bp-desktop} {
    min-height: 100vh;
  }
}

.backIcon {
  transform: rotate(90deg);
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
}

.tokens ul {
  max-height: 11rem;
  overflow: auto;
}

.footer {
  border-top: 1px solid vars.$grey-mist;
  justify-self: flex-end;
  margin-top: auto;
  gap: 0.5rem;
}
