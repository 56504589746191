@use '~styles/utils' as utils;
@use '~styles/mixins/reset.scss' as reset;
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/variables' as var;
@import '~styles/styles.scss';

.root {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.editProfile {
  padding: 3rem 0;
}

.loader {
  left: 50%;
  z-index: map-get(var.$z-index, full-page-loader);
  position: relative;
}

.wrapper {
  display: flex;
  padding-bottom: 2rem;

  @media #{var.$bp-mobile-max} {
    flex-direction: column;
  }
}

.label {
  max-width: utils.toRem(360px);
  flex-shrink: 1;

  &:first-child {
    margin-right: 2rem;
  }

  @media #{var.$bp-mobile-max} {
    max-width: 100%;
  }
}

.input {
  @extend .font-proxima-nova-bold;
}

.button {
  @extend .font-proxima-nova-bold;
  padding: 1rem 2rem;

  @media #{var.$bp-mobile-max} {
    width: 100%;
    padding: 0.25rem 0;
  }
}

.save {
  margin-right: 1rem;

  @media #{var.$bp-mobile-max} {
    margin-bottom: 1rem;
  }
}

.changePassword {
  padding: 0;
  margin-top: 2rem;

  span {
    border: none;
    text-decoration: underline;
  }

  &:hover {
    span {
      border: none;
    }
  }
}

.removeAccount {
  padding: 0;

  span {
    color: var.$color-cf-red;
    border: none;
    text-decoration: underline;
  }

  &:hover {
    span {
      border: none;
    }
  }
}

.clearAll {
  @include reset.reset-button;
  @include typo.Body-Small;
  &:hover {
    opacity: 0.6;
  }
}

.clearAllText {
  text-decoration: underline;
}

.storesEdit {
  max-width: utils.toRem(600px);
  margin-top: 2rem;
}

.stores {
  max-width: max(utils.toRem(360px), 50%);
  margin-top: 2rem;
}
