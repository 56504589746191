@import '~styles/styles.scss';

.counter {
  @extend .font-proxima-nova-bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.root {
  padding-bottom: 4rem;
}

.tableWrapper {
  width: 100%;
  overflow-x: scroll;
}
