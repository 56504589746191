@use '~styles/mixins/button.scss';
@use '~styles/mixins/typography' as typo;
@use '~styles/variables' as var;
$anim-dur: 0.25s;

.tab {
  @include button.base;
  @include typo.Body-Small();

  font-weight: unset;
  color: var.$color-midnight-blue;
  padding: 1rem 1.5rem;
  border-bottom: 3px solid transparent;
  border-radius: 0;

  &:hover {
    border-color: var.$grey-mist;
  }

  &:focus {
    box-shadow: 0px 0px 0px 0rem var.$color-white, 0px 0px 0px 0.1rem var.$utility-blue;
  }

  &--active {
    @include typo.Display-7();

    color: var.$utility-blue;

    &,
    &:hover {
      border-color: var.$utility-blue;
    }
  }
}

.container {
  flex-direction: column;
  width: 21.5rem;
  height: 18rem;
  display: flex;
}

.loader {
  flex-grow: 1;
  min-height: 1px;
  overflow: auto;

  &.loading {
    cursor: progress;
  }
}

.items {
  list-style-type: none;
  padding: 0;
  margin: 0;

  .loading & {
    pointer-events: none;
  }
}

.item {
  @include typo.Body-Small;
  padding: 0.75rem 1rem;
  text-align: start;
  border: none;
  background: none;
  width: 100%;
}

.retailerGroup {
  @extend .item;
  transition: background-color $anim-dur ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover,
  &:focus {
    background-color: rgba(var.$color-cf-blue, 0.06);
  }

  &:active {
    background-color: rgba(var.$color-cf-blue, 0.12);
  }
}

.unit {
  @extend .item;
  transition: background-color $anim-dur ease-in-out;
  &:hover,
  &:focus {
    background-color: rgba(var.$color-cf-blue, 0.06);
  }

  &:active {
    background-color: rgba(var.$color-cf-blue, 0.12);
  }
}

.property {
  @extend .item;
  background-color: var.$color-light-grey;
  color: var.$color-cf-dark-grey;
  text-transform: uppercase;
  line-height: 1;
}

.caret {
  transform: rotate(-90deg);
}

.footer {
  border-top: 1px solid var.$grey-mist;
  align-items: center;
  justify-self: flex-end;
  display: flex;
  gap: 0.5rem;
  margin-top: auto;
  padding: 0.75rem;
  width: 100%;
}

.spinner {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.5rem;
}

.btn {
  font-size: 0.875rem;
  padding: 0.25rem 0.75rem;
}

.done {
  margin-left: auto;
}
