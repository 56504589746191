@use '~styles/mixins/typography' as typo;
@use '../UserManagementPage.module' as ctx;
@use '~styles/variables' as vars;

.search > div:first-child > p {
  @include typo.Body-Small;
  color: vars.$color-cf-dark-grey;
  margin-top: 0.5rem;
}

.header {
  @include typo.Display-6;
  border-bottom: 2px solid vars.$color-cf-blue;

  & td {
    vertical-align: middle;
  }
}

.table {
  table-layout: fixed;
}

.col {
  &--name {
    width: 65%;
  }

  &--status {
    text-align: end;
    width: 35%;
  }
}

.dropdown {
  @include typo.Display-6;
  text-align: start;
  background: none;
  color: inherit;
  border: none;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

.user {
  transition: background-color 150ms ease 0s;
  border-bottom: 1px solid vars.$grey-mist;
  cursor: pointer;

  & td {
    vertical-align: middle;
  }
}

.status {
  white-space: pre-wrap;
  color: vars.$color-white;
  font-size: 0.75rem;

  @each $status, $color in ctx.$statusToColor {
    &--#{$status} {
      background-color: rgba($color, 0.7);
    }
  }
}
