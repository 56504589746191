.auditLog {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav {
  padding: 0.25rem;
  gap: 1rem;
}
