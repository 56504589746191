@use '~styles/utils' as utils;
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/mixins//input.scss' as input;
@use '~styles/variables' as var;
@use '~styles/mixins/reset' as reset;
@import '~styles/styles.scss';

.tabItem {
  @include reset.reset-button;
  display: flex;

  padding: utils.toRem(16px);
  width: 100%;

  align-items: center;

  @media #{var.$bp-mobile-max} {
    flex-direction: column;
    gap: utils.toRem(8px);
    padding: utils.toRem(8px) 0;
  }

  &:hover,
  &:focus,
  &Selected {
    @media #{var.$bp-mobile-min} {
      border-left: 4px solid var.$color-bahama-blue;
      padding-left: utils.toRem(12px);
      background-color: var.$color-background-blue;
    }
  }

  &:hover .tabItemTopTitle,
  &:focus .tabItemTopTitle,
  &Selected .tabItemTopTitle {
    @media #{var.$bp-mobile-min} {
      color: var.$color-bahama-blue;
    }
  }

  &.tabItemSelected {
    cursor: default;
  }

  &Top {
    display: flex;
    width: 100%;

    flex-direction: column;

    justify-content: center;

    &Title {
      font-weight: 700;
      line-height: utils.toRem(28px);
      @media #{var.$bp-mobile-max} {
        line-height: utils.toRem(24px);
      }
    }

    &Subtitle {
      @media #{var.$bp-mobile-max} {
        line-height: utils.toRem(24px);
      }
    }
  }

  &Bottom {
    display: flex;
    width: 100%;

    align-items: flex-end;
    justify-content: center;

    flex-direction: column;

    @media #{var.$bp-mobile-max} {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &Pill {
      border-radius: 4px;
      padding: 0 utils.toRem(8px);
      display: flex;
      align-items: center;
      height: fit-content;
      width: fit-content;
      margin: utils.toRem(3px) 0;

      @media #{var.$bp-mobile-max} {
        margin: 0;
      }

      @each $key, $color in var.$colors {
        &-#{$key} {
          background-color: $color;
        }
      }

      &Text {
        line-height: utils.toRem(22px);

        @media #{var.$bp-mobile-max} {
          font-size: utils.toRem(10px);
          line-height: utils.toRem(20px);
        }
      }
    }

    &Date {
      &Text {
        line-height: utils.toRem(24px);

        @media #{var.$bp-mobile-max} {
          line-height: utils.toRem(18px);
        }
      }
    }
  }
}
