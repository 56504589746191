@use '~styles/mixins/typography.scss' as typo;
@use '~styles/variables' as var;
@import '~styles/styles.scss';

.alertContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 1rem;
  gap: 1rem;
  border-left-width: 0.25rem;
  border-left-style: solid;
  position: relative;

  @each $key, $color in var.$colors {
    &-#{$key} {
      background-color: $color;
    }

    &Border-#{$key} {
      border-left-color: $color;
    }
  }
}

.alertIcon {
  width: 1.5rem;
  height: 1.5rem;

  &Wrapper {
    min-width: unset;
  }
}

.alertTitle {
  @include typo.Body-Main();
  flex: 1;
  word-wrap: break-word;

  &Wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.contentWrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
}

.alertButtonWrapper {
  display: flex;
  align-items: center;
  width: fit-content;
}

.closeButton {
  background: transparent;
  padding: 0;
  cursor: pointer;
  border: 0;

  &:focus {
    outline: 2px solid var.$color-bahama-blue;
    outline-offset: 2px;
  }

  &:hover {
    opacity: 0.8;
  }

  .closeIcon {
    width: 1rem;
    height: 1rem;
    color: var.$color-cf-dark-grey;
  }
}
