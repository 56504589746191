@use '~styles/variables' as var;
@use '~styles/utils' as utils;

.pagination {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;

  // Custom class override for specific use cases
  &.custom {
    margin-top: 0;
  }
}

$disabled_color: var.$color-cf-dark-grey;
$active_color: var.$color-live-medium-blue;
$link_hover_color: var.$color-live-medium-blue;

.pageList {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: flex;

  li {
    display: inline-block;
  }

  &Mobile {
    display: flex;
  }
}

.pageListItem {
  margin: auto 0.35rem;

  @media #{var.$bp-s} {
    margin: auto 1rem;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &Mobile {
    margin: auto 0.75rem;
  }
}

.pageButton {
  // Shape: round
  border-radius: 50%;
  padding: 0;

  // Size: large
  height: 3rem;
  width: 3rem;

  transform: scale(1);
  transition: all 0.3s cubic-bezier(0.17, 0.17, 0.3, 1);

  background-color: transparent;
  border-width: 1px;
  border-color: var.$color-bahama-blue;

  &:hover {
    transform: scale(1.15);

    border-color: var.$color-live-medium-blue;
    background-color: transparent;
  }

  &:disabled {
    border-color: var.$color-cf-dark-grey;
    background-color: transparent;

    border-width: 1px;
  }

  .active {
    color: var.$color-cf-dark-grey;
    border-color: transparent;
  }

  &Icon {
    display: inline-block;
    margin: auto;
    width: 0.5rem;
    height: 0.75rem;
  }
}

.pageLink {
  padding: 0;

  background: none;
  cursor: pointer;
  text-decoration: none;
  color: $active_color;

  background-color: transparent;
  border: none;

  .linkText {
    line-height: 2rem;
  }

  &:hover .linkText {
    color: $link_hover_color;

    background-color: transparent;
    border: none;
  }

  &:hover {
    background-color: transparent;
  }

  &:disabled {
    cursor: unset;
    color: $disabled_color;
    text-decoration: underline;
    pointer-events: none;

    background-color: transparent;
    border: none;
  }

  &Mobile {
    padding: 0;
  }
}

.caret {
  &Left {
    transform: rotate(90deg);
  }

  &Right {
    transform: rotate(-90deg);
  }
}
