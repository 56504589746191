@use '~styles/variables' as var;

$size: 1rem;

.container {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: $size;
  height: $size;
  font-size: 1rem;
}

.radio {
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;

  &:after {
    width: $size;
    height: $size;
    border-radius: 100%;
    background-color: var.$color-light-grey;
    content: '';
    display: inline-block;
    border: 1px solid var.$color-bahama-blue;
  }

  &:checked:after {
    width: $size;
    height: $size;
    border-radius: 100%;
    background-color: var.$color-white;
    border: 0.35rem solid var.$color-bahama-blue;
    content: '';
    display: inline-block;
  }
}
