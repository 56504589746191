@import '~styles/styles.scss';

.search-section {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  flex-direction: column;

  @media #{$bp-s} {
    flex-direction: row;
    align-items: center;
  }
}

.main {
  margin-top: 60px;
}

.subtitle {
  margin-top: 15px;
  margin-bottom: 15px;
}
