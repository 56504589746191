@use '~styles/utils' as utils;
@use '~styles/variables' as var;
@use '~styles/mixins/reset' as reset;
@import '~styles/styles.scss';

.tab {
  @include reset.reset-button;

  padding: utils.toRem(14px) 0;
  border-bottom: 2px solid transparent;

  &Container {
    display: flex;
    gap: utils.toRem(24px);
  }

  &Text {
    line-height: utils.toRem(28px);
    &Fr {
      font-size: utils.toRem(15px);
    }
  }

  &Active {
    border-bottom-color: var.$color-cf-blue;
    cursor: default;
  }
}
