@use '~styles/mixins/input';

.root {
  @include input.input-base();

  &:focus {
    @include input.input-focus();
  }

  &.error {
    @include input.input-error();
  }
}

.errorText {
  @include input.input-error-text();
}
