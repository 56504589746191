@use '~styles/mixins/typography.scss' as typo;
@use '~styles/utils' as utils;
@use '~styles/variables' as var;
@import '~styles/styles.scss';

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: var.$footer-height;
  background-color: $color-midnight-blue;
  color: $color-white;
  padding: 5rem 2.75rem 2.5rem 4rem;
  @media #{$bp-mobile-max} {
    justify-content: flex-start;
    height: var.$footer-mobile-nav-height;
    padding: 3.5rem 2.7rem 2.5rem 2.7rem;
    &.navHidden {
      @media #{$bp-mobile-max} {
        height: var.$footer-mobile-height;
      }
    }
  }
  &.navBar {
    margin-bottom: 4rem;
  }
}

.container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  @media #{$bp-mobile-max} {
    flex-direction: column;
    height: fit-content;
  }
}

.directoryContainer {
  display: flex;
  justify-content: space-between;
  width: 25rem;
  min-width: 22rem;
  @media #{$bp-mobile-max} {
    flex-direction: column;
  }
}

.spacer {
  max-width: 9.5rem;
  min-width: 6rem;
  @media #{$bp-mobile-max} {
    height: 0;
    min-height: 0;
  }
}

.navContainer {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  @media #{$bp-mobile-max} {
    margin-top: 2rem;
  }
}

.navContainerHeader {
  @include typo.Display-5();
  @media #{$bp-mobile-min} {
    font-size: utils.toRem(18px);
    line-height: 1.25;
  }
}

.navItem {
  margin-top: 1rem;
  color: $color-white;
  @include typo.Body-Regular();
  &:hover {
    color: $color-white;
    text-decoration: underline;
  }
  @media #{$bp-mobile-min} {
    font-size: utils.toRem(16px);
    line-height: 1.75;
  }
}

.button {
  font-weight: 600;
  background: none;
  border: none;
  padding: 0;
  display: inline-flex;
  align-items: flex-start;
}

.infoContainer {
  margin-left: 6rem;
}

.policyContainer {
  @media #{$bp-mobile-max} {
    display: flex;
    flex-direction: column;
  }
}

.policyContainer > :not(:first-child) {
  @media #{$bp-mobile-min} {
    margin-left: 1.5rem;
  }
}

.policyLink {
  @include typo.Body-Small();
  color: $color-white;
  line-height: 1.5;
  &:hover {
    color: $color-white;
    text-decoration: underline;
  }
  @media #{$bp-mobile-max} {
    margin-top: 0.5rem;
  }
  @media #{$bp-mobile-min} {
    font-size: utils.toRem(14px);
    line-height: 1.5;
  }
}

.termsContainer {
  @include typo.Body-Small();
  @media #{$bp-mobile-max} {
    margin-top: 2rem;
  }
  @media #{$bp-mobile-min} {
    font-size: utils.toRem(14px);
    line-height: 1.5;
  }
}

.termsContainer > :not(:first-child) {
  margin-top: 0.5rem;
}

.slogan {
  @include typo.Display-5();
  margin-top: 1.5rem;
  font-weight: 700;
  @media #{$bp-mobile-max} {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  @media #{$bp-mobile-min} {
    font-size: utils.toRem(16px);
    line-height: 1.75;
  }
}

.tapestry {
  position: absolute;
  bottom: -21.5rem;
  right: -2.75rem;
  @media #{$bp-mobile-max} {
    bottom: -43.5rem;
    right: -2.75rem;
    &.navHidden {
      bottom: -32.5rem;
      right: -2.75rem;
    }
  }
}

.imageAnchor {
  position: relative;
}

.mobileDivider {
  margin-top: 2rem;
  margin-bottom: 0;
  border: utils.toRem(1px) solid $color-grey;
  width: 100%;
}

.copyrightText {
  @media #{$bp-mobile-max} {
    margin-bottom: 0.75rem;
  }
}
