@use '~styles/utils' as utils;
@use '~styles/mixins/reset' as reset;
@import '~styles/styles.scss';

.pill {
  display: flex;
  align-items: center;
  border: 1px solid $color-athens-grey;
  border-radius: 30px;
  height: fit-content;
  width: fit-content;
  padding: utils.toRem(7px) utils.toRem(16px);
  margin: 0.25rem 0.25rem;
}

.closeButton {
  @include reset.reset-button;
  margin-left: utils.toRem(10px);
  height: 1rem;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.5;
  }
}

.pillContainer {
  overflow-y: scroll;
  max-height: utils.toRem(162px);
}
