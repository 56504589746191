@use '~styles/utils' as utils;
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/mixins/input.scss' as input;
@use '~styles/variables' as var;
@use '~styles/mixins/reset' as reset;
@import '~styles/styles.scss';

.language {
  display: flex;
  flex-direction: column;

  @media #{var.$bp-mobile-min} {
    flex-direction: row;
    gap: utils.toRem(16px);
    align-items: center;
  }

  &Editing {
    flex-direction: column;
    align-items: flex-start;
    gap: utils.toRem(8px);
  }

  &Image {
    @media #{var.$bp-mobile-min} {
      flex-direction: column;
      align-items: flex-start;
      gap: utils.toRem(8px);
    }
  }
}

.readOnly {
  &Button {
    padding: 0;

    &:hover span {
      border-bottom: 2px solid transparent;
    }

    &Text {
      line-height: utils.toRem(24px);
      font-size: utils.toRem(14px);

      @media #{var.$bp-mobile-min} {
        line-height: utils.toRem(26px);
        font-size: utils.toRem(16px);
      }
    }
  }

  &Link {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: fit-content;
    overflow: hidden;

    @media #{var.$bp-mobile-min} {
      flex-direction: row;
      gap: utils.toRem(16px);
    }

    &Text {
      line-height: utils.toRem(24px);
      white-space: nowrap;
      text-overflow: ellipsis;
      &Ellipsis {
        overflow: hidden;
      }
    }
  }
}

.textInput {
  padding: utils.toRem(11px) utils.toRem(12px);
  @include typo.Body-Main();
  color: var.$color-cf-blue;
  border-bottom-color: var.$color-cf-blue;
  border-width: 1px;
  width: 100%;

  &:focus {
    border-width: 1px;
  }

  &Error {
    @include input.input-error();
    border-width: 1px;
  }
}

.image {
  &Display {
    width: utils.toRem(288px);
    height: utils.toRem(144px);

    &ReadOnly {
      margin-top: utils.toRem(8px);

      @media #{var.$bp-mobile-min} {
        margin-top: 0;
      }
    }

    &Icon {
      margin-top: utils.toRem(8px);
    }
  }
  &CTA {
    &Container {
      @include reset.reset-button;
      background-color: var.$color-light-grey;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      margin-top: utils.toRem(8px);
      gap: utils.toRem(16px);

      width: utils.toRem(288px);
      height: utils.toRem(144px);

      @media #{var.$bp-mobile-min} {
        margin-top: 0;
      }

      &:disabled {
        > img {
          filter: grayscale(1);
          opacity: 0.5;
        }
        > span {
          color: var.$color-dusty-gray;
        }
      }
    }
  }
  &Editing {
    &Container {
      @include reset.reset-button;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      gap: utils.toRem(16px);

      width: utils.toRem(288px);
      height: utils.toRem(144px);

      &CTA {
        border: 1px dashed var.$color-cf-blue;
      }
    }
  }
}
