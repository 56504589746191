@use '~styles/variables' as var;
@use '~styles/mixins/reset.scss' as reset;
@use '~styles/mixins/typography.scss' as typo;
@use '~styles/utils/' as utils;

.tosCheckboxError:after {
  border: 1px solid var.$horizon-red !important;
}

.clearAll {
  @include reset.reset-button;
  @include typo.Body-Small;
}

.clearAllText {
  text-decoration: underline;
}

.pillContainer {
  overflow-y: scroll;
  max-height: utils.toRem(162px);
}

.underline {
  display: inline-block;
  width: fit-content;

  &:after {
    content: '';
    display: block;
    width: calc(100% + 5px);
    margin-top: utils.toRem(-8px);
    border-bottom: 6px solid var.$color-sky-blue;

    @media #{var.$bp-s} {
      margin-top: utils.toRem(-12px);
      border-bottom: 12px solid var.$color-sky-blue;
    }

    @media #{var.$bp-m} {
      margin-top: utils.toRem(-18px);
    }
  }
}

.helpLinks {
  border-top: solid 1px var.$grey-mist;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var.$color-white;
  padding: 1rem;

  @media #{var.$bp-s} {
    padding: 1rem 0 1rem 0;
    display: flex;
    background: none;
    border: none;
  }
}

.terms {
  line-height: utils.toRem(18px);
  margin: 0.5rem 0;
  @media #{var.$bp-s} {
    line-height: utils.toRem(24px);
    margin: 0.25rem 0;
  }
}

.termsLink {
  @include reset.reset-button();
  color: var.$color-bahama-blue;
  text-decoration-line: underline;
}
