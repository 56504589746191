@use '~styles/mixins/typography';
@use '~styles/mixins/input';
@use '~styles/variables' as var;
@use '~styles/mixins/reset';

.wrapper {
  width: 100%;
}

.container {
  position: relative;
}

.root {
  @include input.input-base();

  &:focus {
    @include input.input-focus();
  }

  &.error {
    @include input.input-error();
  }
}

.leftIcon {
  padding-left: 3rem;
}

.rightIcon {
  padding-right: 3rem;
}

.errorText {
  @include input.input-error-text();
}

.iconButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;

  &_left {
    left: 1rem;
  }

  @include reset.reset-button();
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  width: fit-content;

  &_left {
    left: 1rem;
  }
}
