@use '~styles/mixins/input';

.container {
  display: flex;
  gap: 0.5rem 2rem;
  flex-wrap: wrap;
}

.error {
  @include input.input-error-text();
}

.hasError .error:global(.invalid-feedback) {
  display: block;
}

.date {
  flex-basis: 15rem;
}

.time {
  flex-basis: 10rem;
}
