@use 'sass:color';
@use '~styles/variables.scss' as var;
@use '~styles/utils';
@use '~styles/mixins/typography.scss';

$primary-color: var.$color-bahama-blue;
$secondary-color: var.$horizon-red;
$disabled-color: var.$color-dusty-gray;
$border-width: 2px;
$anim-dur: 0.25s;

@mixin base {
  @include typography.Display-5();

  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  border-radius: utils.toRem(4px);
  border: none;
  background: none;
  padding: utils.toRem(12px - $border-width) utils.toRem(30px - $border-width);

  &:disabled {
    pointer-events: none;
    cursor: default;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 0.2rem var.$color-white, 0px 0px 0px 0.3rem $primary-color;
  }
}

@mixin solid($theme-color) {
  transition: color $anim-dur ease-in-out, background-color $anim-dur ease-in-out,
    border-color $anim-dur ease-in-out;
  color: var.$color-white;
  border: $border-width solid $theme-color;
  background-color: $theme-color;

  &:hover {
    background-color: color.adjust($theme-color, $lightness: -10%);
    border-color: color.adjust($theme-color, $lightness: -10%);
  }

  &:disabled {
    color: var.$color-white;
    background-color: $disabled-color;
    border: $border-width solid $disabled-color;
  }
}

@mixin outline($theme-color) {
  transition: color $anim-dur ease-in-out, background-color $anim-dur ease-in-out;
  color: $theme-color;
  border: $border-width solid $theme-color;
  background-color: transparent;

  &:hover {
    background-color: color.adjust($theme-color, $lightness: 65%);
  }

  &:disabled {
    color: $disabled-color;
    background-color: transparent;
    border: $border-width solid $disabled-color;
  }
}

@mixin primary-theme {
  &-solid {
    @include solid($primary-color);
  }
  &-outline {
    @include outline($primary-color);
  }
}

@mixin secondary-theme {
  &-solid {
    @include solid($secondary-color);
  }
  &-outline {
    @include outline($secondary-color);
  }
}

@mixin size-small {
  padding: utils.toRem(12px - $border-width) utils.toRem(12px - $border-width);
}

@mixin size-large {
  padding: utils.toRem(16px - $border-width) utils.toRem(32px - $border-width);
}

@mixin modal-button-styles {
  padding: utils.toRem(13px) utils.toRem(23px);
  font-size: utils.toRem(16px);
  line-height: utils.toRem(24px);

  @media #{var.$bp-mobile-max} {
    padding: utils.toRem(9px) utils.toRem(16px);
    font-size: utils.toRem(14px);
    line-height: utils.toRem(20px);
  }
}
