@use '~styles/mixins/input';
@use '~styles/mixins/typography';

.group {
  @include input.input-base();
  align-items: center;
  padding: 0;
  display: flex;

  &:focus-within {
    @include input.input-focus();
  }

  &:global(.is-invalid) {
    @include input.input-error();
  }
}

.input {
  @include typography.Display-6();
  padding: 0.85rem 1rem;
  color: currentColor;
  background: none;
  appearance: none;
  flex-grow: 1;
  border: none;
  &:focus {
    outline: none;
  }
}

%pend {
  appearance: none;
  border: none;
  padding: 0;
}

.prepend {
  @extend %pend;
  margin-left: 1rem;
  flex-shrink: 0;
}

.append {
  @extend %pend;
  margin-right: 1rem;
  flex-shrink: 0;
}
