@use '~styles/utils' as utils;
@use '~styles/mixins/button.scss';
@use '~styles/mixins/reset.scss' as reset;
@use '~styles/variables' as var;
@import '~styles/styles.scss';

$widgetPadding: utils.toRem(40px);

.signInPage {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - var.$footer-mobile-height - var.$header-mobile-height);

  @media #{var.$bp-s} {
    min-height: calc(100vh - var.$footer-height - var.$header-height);
    align-items: center;
  }
}

.widgetWrapper {
  position: relative;
  width: 100%;
  padding: $widgetPadding;
  background-color: var.$color-white;
  z-index: map-get(var.$z-index, widget-container);

  @media #{var.$bp-s} {
    margin-top: 3rem;
    max-width: 35rem;
  }
}

.widgetWrapperLocked {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.underline {
  display: inline-block;
  width: fit-content;

  &:after {
    content: '';
    display: block;
    width: calc(100% + 5px);
    margin-top: utils.toRem(-14px);
    border-bottom: 6px solid $color-sky-blue;

    @media #{var.$bp-s} {
      margin-top: utils.toRem(-12px);
      border-bottom: 12px solid $color-sky-blue;
    }

    @media #{var.$bp-m} {
      margin-top: utils.toRem(-18px);
    }
  }
}

.text {
  margin-bottom: 2rem;
}

.forgotPasswordLinks {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  @media #{var.$bp-s} {
    align-items: center;
    margin-top: 1rem;
  }
}

.terms {
  line-height: utils.toRem(18px);
  margin: 0.5rem 0;
  @media #{var.$bp-s} {
    text-align: center;
    line-height: utils.toRem(24px);
    margin: 0.25rem 0;
  }
}

.termsLink {
  @include reset.reset-button();
  color: var.$color-bahama-blue;
  text-decoration-line: underline;
}

.helpLinks {
  border-top: solid 1px var.$grey-mist;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var.$color-white;
  padding: 1rem $widgetPadding 2rem $widgetPadding;

  @media #{var.$bp-s} {
    padding: 1rem 0 2rem 0;
    display: flex;
    background: none;
    border: none;
  }
}

.button {
  @include button.base;
  @include button.primary-theme;
  width: 100%;
}
