@use '~styles/mixins/input';
@use '~styles/variables' as var;

.error {
  @include input.input-error-text();
}

.required {
  &::after {
    margin-right: 0.5rem;
    color: var.$color-cf-red;
    content: '*';
  }
}

.container.hasError .error:global(.invalid-feedback) {
  display: block;
}
