@use '~styles/mixins/typography' as typo;
@use '~styles/variables' as vars;

$statusToColor: (
  'in-recovery': vars.$utility-yellow,
  'deactivated': vars.$utility-red,
  'pending': vars.$color-cf-blue,
  'active': vars.$utility-green,
);

.tapestry {
  inset: 0 0 auto auto;
  position: absolute;
  width: 7.5rem;
  margin: 0;

  @media #{vars.$bp-desktop-max} {
    width: 3.5rem;
  }
}

.nav {
  @include typo.Body-Large;
  & button:global(.nav-link) {
    transition: border-color 250ms ease 0s, color 250ms ease 0s;
    border-width: 0px;
    border-bottom-width: 2px;
    font-weight: bold;
  }
  gap: 1.5rem;
}

.sidePanelFrame {
  box-shadow: -0.5rem 0.5rem 1rem 0.25rem rgba(vars.$color-black, 0.04);
  background-color: vars.$color-white;
  align-self: stretch;
}

.sidePanel {
  position: sticky;
  top: 0;

  @media #{vars.$bp-desktop} {
    min-height: 100vh;
  }
}
